import React, { useState, useEffect, useRef } from 'react';
import { ReloadOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import './pull-to-refresh.scss';
import { Capacitor } from '@capacitor/core';

const distanceToRefresh = 160;

const PullToRefresh = () => {
  const [isRefreshing, setIsRefreshing] = useState(false);
  const pullProgressRef = useRef(0);
  const startYRef = useRef(0);
  const spinRef = useRef<HTMLDivElement | null>(null);
  const animationFrameRef = useRef<number | null>(null);
  const isNativePlatform = Capacitor.isNativePlatform();

  const scrollableParentCache = new WeakMap<HTMLElement, HTMLElement | null>();

  const getScrollableParent = (node: HTMLElement | null): HTMLElement | null => {
    if (!node) {
      return null;
    }
    if (scrollableParentCache.has(node)) {
      return scrollableParentCache.get(node) || null;
    }

    let currentNode: HTMLElement | null = node;
    while (currentNode) {
      const { overflowY, overflow } = window.getComputedStyle(currentNode);
      if (overflowY === 'auto' || overflowY === 'scroll' || overflow === 'auto' || overflow === 'scroll') {
        scrollableParentCache.set(node, currentNode);
        return currentNode;
      }
      currentNode = currentNode.parentElement;
    }

    scrollableParentCache.set(node, null);
    return null;
  };

  useEffect(() => {
    if (!isNativePlatform) {
      return;
    }

    const handleTouchStart = (event: TouchEvent) => {
      const scrollableParent = getScrollableParent(event.target as HTMLElement);
      if (isRefreshing || (scrollableParent && scrollableParent.scrollTop > 0)) {
        startYRef.current = 0;
        return;
      }
      startYRef.current = event.touches[0].clientY;
    };

    const handleTouchMove = (event: TouchEvent) => {
      if (isRefreshing || startYRef.current === 0) {
        return;
      }
      const currentY = event.touches[0].clientY;
      const distance = currentY - startYRef.current;

      if (distance > 0 && distance < distanceToRefresh * 1.5) {
        cancelAnimationFrame(animationFrameRef.current!);
        animationFrameRef.current = requestAnimationFrame(() => {
          pullProgressRef.current = distance;
          updateSpinPosition();
        });
      }
    };

    const handleTouchEnd = async () => {
      if (pullProgressRef.current >= distanceToRefresh) {
        setIsRefreshing(true);
        animateToFinalPosition(() => {
          window.location.reload();
        });
      } else {
        animateBack();
      }
    };

    const animateToFinalPosition = (callback: () => void) => {
      let start = pullProgressRef.current;
      const target = distanceToRefresh * 1.5;
      const duration = 200;
      const startTime = performance.now();

      const animate = (time: number) => {
        const elapsed = time - startTime;
        const progress = Math.min(elapsed / duration, 1);
        pullProgressRef.current = start + (target - start) * progress;
        updateSpinPosition();

        if (progress < 1) {
          animationFrameRef.current = requestAnimationFrame(animate);
        } else {
          callback();
        }
      };

      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
      animationFrameRef.current = requestAnimationFrame(animate);
    };

    const animateBack = () => {
      let start = pullProgressRef.current;
      const duration = 300;
      const startTime = performance.now();

      const animate = (time: number) => {
        const elapsed = time - startTime;
        const progress = Math.min(elapsed / duration, 1);
        pullProgressRef.current = start * (1 - progress);
        updateSpinPosition();

        if (progress < 1) {
          requestAnimationFrame(animate);
        }
      };

      requestAnimationFrame(animate);
    };

    const updateSpinPosition = () => {
      if (spinRef.current) {
        spinRef.current.style.top = `${-40 + pullProgressRef.current / 1.2}px`;
        spinRef.current.style.opacity = pullProgressRef.current > 20 ? '1' : '0';
        spinRef.current.style.transform = `translateX(-50%) rotate(${pullProgressRef.current * 2}deg)`;
      }
    };

    document.addEventListener('touchstart', handleTouchStart);
    document.addEventListener('touchmove', handleTouchMove);
    document.addEventListener('touchend', handleTouchEnd);

    return () => {
      document.removeEventListener('touchstart', handleTouchStart);
      document.removeEventListener('touchmove', handleTouchMove);
      document.removeEventListener('touchend', handleTouchEnd);
    };
  }, [isRefreshing]);

  return isNativePlatform ? (
    <div>
      <div ref={spinRef} className="pull-to-refresh-spinner-wrapper ">
        <Spin
          indicator={
            <ReloadOutlined
              style={{
                fontSize: 32,
                color: '#AEB5BE',
                transition: isRefreshing ? 'transform 0.5s linear' : 'none',
              }}
              spin={isRefreshing}
            />
          }
        />
      </div>
    </div>
  ) : null;
};

export default PullToRefresh;
