import { ResidentTypesEnum } from 'common/enums/resident-types.enum';
import { IResidentAddresses, IResidentDetails } from 'common/services/residents-service/residents.service.types';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

export const isOccupant = (ownerType?: ResidentTypesEnum) => {
  return (
    ownerType !== ResidentTypesEnum.Renter &&
    ownerType !== ResidentTypesEnum.Owner &&
    ownerType !== ResidentTypesEnum.Manager &&
    ownerType !== ResidentTypesEnum.NonResident &&
    ownerType !== ResidentTypesEnum.Employee &&
    ownerType !== ResidentTypesEnum.ClubMember
  );
};

export const isHost = (ownerType?: ResidentTypesEnum) => {
  return ownerType === ResidentTypesEnum.Renter || ownerType === ResidentTypesEnum.Owner;
};

export const IsValidRenter = (residentDetails: IResidentDetails) => {
  const isRenterOnly = residentDetails.addresses.every(
    (i) => i.ownershipType === ResidentTypesEnum.Renter || i.relatedTo?.ownershipType === ResidentTypesEnum.Renter,
  );

  const isRentalPeriodExpired = residentDetails.addresses.every((i) => !IsRentPeriodValid(i));

  if (isRenterOnly && isRentalPeriodExpired) {
    return false;
  }

  return true;
};

const IsRentPeriodValid = (residentAddress: IResidentAddresses) => {
  const today = dayjs().startOf('day');

  const startDateString = residentAddress.rentStartDate ?? residentAddress.relatedTo?.rentStartDate;
  const endDateString = residentAddress.rentEndDate ?? residentAddress.relatedTo?.rentEndDate;

  return today.isSameOrAfter(startDateString, 'day') && today.isSameOrBefore(endDateString, 'day');
};
