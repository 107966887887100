import { makeAutoObservable } from 'mobx';
import { FEATURES } from './feature.constants';
import React from 'react';
import { IFeature } from 'common/services/feature-service/feature-service.types';
import featureService from 'common/services/feature-service/feature.service';
import { FeatureName } from 'common/enums/feature-name.enum';
import { getItem, removeItem, setItem } from 'common/helpers/authorization-store.helper';

class FeatureStore {
  features: IFeature[] = [];

  constructor() {
    makeAutoObservable(this);
    this.restoreFeatures();
  }

  setFeatures(features: IFeature[]) {
    const item = features ? features : [];
    setItem(FEATURES, JSON.stringify(item));
    this.features = features;
  }

  restoreFeatures() {
    const source = getItem(FEATURES);
    this.features = source ? JSON.parse(source) : [];
  }

  clearFeatures() {
    removeItem(FEATURES);
    this.features = [];
  }

  async requestFeatures() {
    const features = await featureService.getFeatures();
    this.setFeatures(features);
  }

  IsEnabled(featureName: FeatureName) {
    return !!this.features?.find((feature) => feature.name === featureName)?.isEnabled;
  }

  get isCardAccessEnabled() {
    return this.IsEnabled(FeatureName.CA3000) || this.IsEnabled(FeatureName.CA4K);
  }

  get isCA3000ExpirationsEnabled() {
    return this.IsEnabled(FeatureName.CA3000) && this.IsEnabled(FeatureName.CA3000Expirations);
  }

  get isNorthStar() {
    return this.IsEnabled(FeatureName.NorthStar);
  }

  get isConnectEditPinEnabled() {
    return this.IsEnabled(FeatureName.ConnectEditPin);
  }

  get isEIQIntelliTicketEnabled() {
    return this.IsEnabled(FeatureName.EIQIntelliTicket);
  }

  get isGuestLimitationsEnabled() {
    return this.IsEnabled(FeatureName.GuestLimitations);
  }

  get isEIQViolationEnabled() {
    return this.IsEnabled(FeatureName.EIQViolation);
  }

  get isCitationsEnabled() {
    return this.isEIQIntelliTicketEnabled || this.isEIQViolationEnabled;
  }
}

const featureStore = new FeatureStore();
export const FeatureStoreContext = React.createContext<FeatureStore>(featureStore);
export default featureStore;
