import React from 'react';
import styles from './alert-modal.module.scss';
import { IAlertModalProps } from './alert-modal.types';
import { Button, Modal, Space } from 'antd';

const AlertModal = ({ isOpen, onClose, title, message }: IAlertModalProps) => {
  const hideModal = (isSuccess: boolean) => {
    if (onClose) {
      onClose();
    }
  };

  return (
    <Modal centered title={title} width={350} open={isOpen} wrapClassName="custom-modal" closable={false}>
      <Space className={styles.modalBody}>{message}</Space>
      <Space className="footer">
        <Button className={styles.button} type="primary" onClick={() => hideModal(false)}>
          Close
        </Button>
      </Space>
    </Modal>
  );
};

export default AlertModal;
