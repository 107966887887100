import { PushNotifications, ActionPerformed, Token } from '@capacitor/push-notifications';
import { Capacitor } from '@capacitor/core';
import userAuthStore from '../../../../common/stores/user-auth/user-auth.store';
import pushNotificationService from '../push-notification-service/push-notification.service';
import featureStore from 'common/stores/features/feature.store';

class FcmService {
  async initPush() {
    if (Capacitor.isNativePlatform()) {
      this.registerPush();
    }
  }

  async unsubPush() {
    if (Capacitor.isNativePlatform()) {
      this.removePush();
    }
  }

  private async registerPush() {
    if (!userAuthStore.webGuestResidentId) {
      console.error('Trying to register push notifications for user with unknown id');
      return;
    }

    let permStatus = await PushNotifications.checkPermissions();

    if (permStatus.receive === 'prompt') {
      permStatus = await PushNotifications.requestPermissions();
    }

    if (permStatus.receive !== 'granted') {
      console.log('User denied permissions!');
    }

    await PushNotifications.register();

    PushNotifications.addListener('registration', (token: Token) => {
      if (!userAuthStore?.webGuestResidentId) {
        return;
      }

      function updatePushData(token: string, registrationId: string) {
        localStorage.setItem('registration', registrationId);
        localStorage.setItem('handle', token);
        localStorage.setItem('device_resident_id', `resident_id:${String(userAuthStore.webGuestResidentId)}`);
      }

      const platform = Capacitor.getPlatform() === 'android' ? 'fcm' : 'apns';

      const registrationId = localStorage.getItem('registration');

      if (!registrationId) {
        pushNotificationService
          .registerDevice(userAuthStore.webGuestResidentId, { platform: platform, pushToken: token.value })
          .then((res) => {
            updatePushData(token.value, res);
          })
          .catch((e) => {
            console.error(e);
          });
      } else {
        const handle = localStorage.getItem('handle');
        const device_resident_id = localStorage.getItem('device_resident_id');

        // it token or resident id has changed (logout/login), update the device registration
        if (handle !== token.value || device_resident_id !== `resident_id:${String(userAuthStore.webGuestResidentId)}`) {
          pushNotificationService
            .updateDevice(userAuthStore.webGuestResidentId, registrationId, {
              platform: platform,
              pushToken: token.value,
            })
            .then((res) => {
              updatePushData(token.value, registrationId);
            });
        }
      }
      console.log('Push notification token:', token);
    });

    PushNotifications.addListener('registrationError', (err) => {
      console.error('Registration error: ', err.error);
    });

    PushNotifications.addListener('pushNotificationActionPerformed', async (notification: ActionPerformed) => {
      console.log('Push notification action performed', notification.actionId, notification.inputValue);
      const body = notification.notification.body || '';
      const title = notification.notification.title || '';

      //TODO :: need to be refactored
      if (title === CITATION_NOTIFICATION_TITLE) {
        window.location.href = '/eiq-connect/citations';
      } else if (body.toLowerCase().includes('vehicle')) {
        window.location.href = '/eiq-connect/vehicles';
      } else {
        window.location.href = '/eiq-connect/notifications';
      }
    });
  }

  private async removePush() {
    if (!userAuthStore?.webGuestResidentId) {
      console.error('Cannot remove push notifications without a resident ID');
      return;
    }

    if (Capacitor.isNativePlatform()) {
      const registrationId = localStorage.getItem('registration');
      const token = localStorage.getItem('handle');
      const platform = Capacitor.getPlatform() === 'android' ? 'fcm' : 'apns';

      if (!registrationId || !token) {
        console.error('Missing registration ID or token. Cannot unregister push notifications.');
        return;
      }

      try {
        await PushNotifications.unregister();
        pushNotificationService.removeDevice(userAuthStore.webGuestResidentId, registrationId, {
          platform: platform,
          pushToken: token,
        });

        localStorage.removeItem('registration');
        localStorage.removeItem('handle');
        localStorage.removeItem('device_resident_id');
      } catch (error) {
        console.error('Error while unregistering push notifications:', error);
      }
    }
  }
}

export const fcmService = new FcmService();
export default fcmService;

const CITATION_NOTIFICATION_TITLE = 'Citation notification';
